<template>
  <div>
    <v-dialog
      v-model="dialog"
      hide-overlay
      width="600"
      height="500"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on: dialog }">
        <v-tooltip transition="fade-transition" top>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn text color="success" v-on="{ ...dialog, ...tooltip }" dark
              ><v-icon>mdi-note-text</v-icon>
            </v-btn>
          </template>
          <span>View Details</span>
        </v-tooltip>
      </template>
      <v-card width="600" height="500">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="mx-2" icon @click="generateReport"
              ><v-icon>mdi-file-pdf</v-icon> Export
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div id="pdf" class="mx-4">
          <h1 class="bg-custom">Payment confirmation</h1>
          <div class="row fact-info mt-3 mx-2">
            <div class="col-4">
              <h5>Invoice to:</h5>
              <p>{{ patientDetail.fullname }}</p>
            </div>
            <div class="col-4">
              <h5>Payment Type</h5>
              <p>{{ typesShow(item.type) }}</p>
            </div>
            <div class="col-3">
              <h5>Procedure</h5>
              <p>
                {{ procedure.treatmentType != null ? "Treatment" : "Surgery" }}
              </p>
            </div>
          </div>
          <v-divider></v-divider>
          <template
            v-if="item.type == 'Stripe' || item.type == 'External_Card_Reader'"
          >
            <div class="row fact-info mt-3 mx-2">
              <div class="col-4">
                <h5>Card Type</h5>
                <p>
                  <template v-if="card != null && card.brand == 'Visa'"
                    >Visa <icon-visa :width="45" :height="35" />
                  </template>
                  <template
                    v-else-if="card != null && card.brand == 'mastercard'"
                  >
                    MasterCard <icon-mastercard :width="45" :height="35" />
                  </template>
                  <template v-else>
                    {{ card != null ? card.brand : "" }}
                  </template>
                </p>
              </div>
              <div class="col-4">
                <h5>No.</h5>
                <p>**** **** **** {{ card != null ? card.last4 : "" }}</p>
              </div>
              <div class="col-3">
                <h5>Country</h5>
                <p>{{ card != null ? card.country : "" }}</p>
              </div>
            </div>
            <v-divider></v-divider>
          </template>
          <div class="row fact-info mt-3 mx-2">
            <div class="col-4">
              <h5>Details:</h5>
              <p>{{ prettyDescrip(item.details) }}</p>
            </div>
            <div class="col-4">
              <h5>Payment Date</h5>
              <p>{{ prettyDate(item.createAt) }}</p>
            </div>
            <div class="col-3">
              <h5>Amount</h5>
              <p>{{ item.amount | currency }}</p>
            </div>
          </div>
          <v-divider></v-divider>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import moment from "moment";
import IconVisa from "@/components/icons/iconVisa.vue";
import IconMastercard from "@/components/icons/iconMastercard.vue";
export default Vue.extend({
  name: "crm-pdf-view",
  props: {
    item: Object,
  },
  components: { IconMastercard, IconVisa },
  data() {
    return {
      dialog: false,
      datacard: null,
    };
  },
  async mounted() {
    if (this.item.type == "External_Card_Reader") {
      const card = this.item.stripeCardId.includes("{")
        ? JSON.parse(this.item.stripeCardId).card_present
        : "CardReader";

      if (card == "CardReader") {
        await this.getPaymentIntent();
      } else {
        this.datacard = card;
      }
    }
  },

  computed: {
    ...mapState("crmMedicFormModule", ["patientDetail", "procedure"]),
    card() {
      if (this.item.type == "Stripe") {
        const idc = this.item.stripeCardId;
        const card = this.patientDetail.stripeCards.filter(
          (card) => card.id == idc
        )[0];
        if (card == undefined) {
          return null;
        }
        return card;
      }
      if (this.item.type == "External_Card_Reader") {
        return this.datacard;
      }
      return null;
    },
  },
  methods: {
    prettyDescrip(descrip) {
      if (descrip.length > 28) {
        return descrip.substr(0, 28);
      } else {
        return descrip;
      }
    },

    generateReport() {
      window.html2canvas = html2canvas;
      let doc = new jsPDF("p", "pt", "a4");
      doc.html(document.querySelector("#pdf"), {
        callback: function (pdf) {
          pdf.save("Payment-confirm.pdf");
        },
      });
      this.cancel();
    },

    cancel() {
      this.dialog = false;
    },
    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    typesShow(type) {
      if (type == "External_Card_Reader") {
        return "Card Reader";
      }
      return type;
    },

    async getPaymentIntent() {
      const resData = (
        await getAPI.post("/payment/get-payment-intent", {
          id: this.item.stripeChargeId,
        })
      ).data.charges.data[0].payment_method_details;

      this.datacard = resData.card_present;
    },
  },
});
</script>
<style lang="scss" scope>
.fact-info {
  text-align: justify;
  justify-content: center;
}
.bg-custom {
  margin-top: 2rem;
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  text-transform: uppercase;
  background: #26a69a;
  border-radius: 0.25em;
  color: #fff;
  margin: 0 0 1em;
  padding: 0.5em 0;
}
#pdf {
  margin-top: 16px;
  height: 300px;
  width: 500px;
}
</style>
